import appInsights from '../telemetry/appInsights';
import { synchronizeCalls } from '../api/apiServices';
import fetchCallHistory from '../api/fetchCallHistory';


export default async function syncCalls(mondayToken, phone, action = null) {
	console.log('Sync Calls func... Event', action);
	appInsights.trackEvent({ name: `missed_calls_clicked` });
	try {
		const syncResult = mondayToken ? await synchronizeCalls(mondayToken, action) : null;
		if (!syncResult) {
			console.info('No call was synced.');
			return null;
		}

		return await fetchCallHistory(phone, mondayToken);
	} catch (e) {
		console.log('Sync error', e);
		return null
	}
}