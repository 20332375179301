import axios from "axios";

const fetchRecord = async (credentials, callId, date) => {
    const { phone, mondayToken } = credentials;

    if (phone && mondayToken) {
        try {
            const record = await axios.post(
                `${process.env.REACT_APP_API_URL}call/record`,
                { callId, date },
                { headers: { Authorization: `Bearer ${mondayToken}` } }
            );

            return record.data;
        } catch (error) {
            if (error.response.status === 300) {
                return 300; // Only provide report in recent 6 months
            }
            console.error("Getting history error", error);
            return null;
        }
    }
};

export default fetchRecord;