import { useContext } from 'react';
import { AppContext } from '../../context';
import { Flex, Text } from 'monday-ui-react-core';
import { Heading } from 'monday-ui-react-core/next';
import appInsights from '../../telemetry/appInsights';

export default function NoHistory() {
	const { state: { phone } } = useContext(AppContext);

	return (
		<Flex direction={Flex.directions.COLUMN} justify={Flex.justify.CENTER} align={Flex.align.CENTER}
		      style={{ height: '80vh' }}
		>
			<Heading type={Heading.types.H3}>There is no history for this phone number yet..</Heading>

			<Text type={Text.types.TEXT2}>
				{/* eslint-disable-next-line no-script-url */}
				<a href='!#' onClick={(e) => {
					e.preventDefault();
					console.log('Call clicked: ', phone[0]);
					appInsights.trackEvent({ name: `call_clicked ${phone[0]}` });
					window.location.href = `zoomphonecall:${phone[0]}`;
				}}>
					Try calling them? ☝️
				</a>
			</Text>

		</Flex>
	);
}