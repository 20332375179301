function dateToDays(dateString) {
	const currentDate = new Date();
	currentDate.setHours(0, 0, 0, 0);

	const specifiedDate = new Date(dateString);

	// return today
	if (currentDate <= specifiedDate) {
		return 'Today';
	}

	const timeDifference = Math.abs(
		currentDate.getTime() - specifiedDate.getTime()
	);

	const daysPassed = Math.floor(timeDifference / (
		1000 * 60 * 60 * 24
	));

	// return this month
	if (daysPassed < 30) {

		return daysPassed === 0 ? 'Yesterday' : `${daysPassed + 1}d`;

	}

	const monthsPassed = Math.floor(daysPassed / 30);
	const remainingDays = daysPassed % 30;
	const weeksPassed = Math.floor(remainingDays / 7);

	if (weeksPassed > 0) {
		return `${monthsPassed}m ${weeksPassed}w`;
	}
	else {
		return `${monthsPassed}m`;
	}
}

function secondsToTime(seconds) {
	if (seconds < 60) {
		return `${seconds}s`;
	}
	else {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;
		return `${minutes}m ${remainingSeconds}s`;
	}
}

function toAmericanTime(dateString) {
	const date = new Date(dateString);

	const mm = String(date.getMonth() + 1).padStart(2, '0');
	const dd = String(date.getDate()).padStart(2, '0');
	const yy = String(date.getFullYear()).substring(2);

	let hours = date.getHours();
	const minutes = String(date.getMinutes()).padStart(2, '0');
	const amOrPm = hours >= 12 ? 'PM' : 'AM';
	hours = hours % 12;
	hours = hours ? hours : 12; // соответствует 12 часам (0 в 24-часовом формате)
	const time = [hours, minutes].join(':');

	return [mm, dd, yy].join('/') + ' ' + time + ' ' + amOrPm;
}

function toShortDataTime(dateString) {
	const date = new Date(dateString);

	const dateOptions = { day: 'numeric', month: 'short' };
	const dateFormatter = new Intl.DateTimeFormat('en-US', dateOptions);
	const shortDate = dateFormatter.format(date);

	const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
	const timeFormatter = new Intl.DateTimeFormat('en-US', timeOptions);
	const shortTime = timeFormatter.format(date);

	return { shortDate, shortTime };
}

function month(dateString) {
	const date = new Date(dateString);
	const monthFormatter = new Intl.DateTimeFormat('en-US', {month: 'long', year: 'numeric'});

	return  monthFormatter.format(date);
}


const formatTime = { dateToDays, secondsToTime, toAmericanTime, toShortDataTime, month };

export default formatTime;