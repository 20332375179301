import { Flex } from 'monday-ui-react-core';
import React, { useContext, useEffect, useState } from "react";
import { Heading } from 'monday-ui-react-core/next';
import { AppContext } from '../../context';
import AddColumnButtonOnBoard from '../../components/buttons/AddColumnButton/AddColumnButtonOnBoard';
import Modal from '../../components/Modal/Modal';


export default function BoardView() {

	const { state: { theme, isOnBoard } } = useContext(AppContext);
	const [isBoardSyncing, setIsBoardSyncing] = useState(false);

	useEffect(() => {
		console.log('\n\nBoard View...');
	}, []);


	const toggleIsSyncing = async (value) => {
		await setIsBoardSyncing(value);
	};

	return (
		<Flex direction={Flex.directions.COLUMN} justify={Flex.justify.CENTER} align={Flex.align.CENTER}
		      style={{ height: '80vh' }} className={theme} gap={Flex.gaps.LARGE}>

			<Heading type={Heading.types.H2}>
				Zoom Phone integration in monday CRM
			</Heading>

			{isBoardSyncing &&
				(<Modal loadingText={`Please do not close this window while this message is visible. We calculate the calls you’ve done...`}/>)}

			<Heading type={Heading.types.H3}>
				{isOnBoard ?
					`In order to remove 'Total Zoom Calls' column from this Board:` :
					`See how many calls were done for each item 👇`}
			</Heading>

			<AddColumnButtonOnBoard toggleIsSyncing={toggleIsSyncing}/>

		</Flex>
	);
}