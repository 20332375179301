import React, { createContext, useState } from "react";

export const AppContext = createContext(null);

export const ContextProvider = ({ children }) => {
    const [state, setState] = useState({
        boardId     : null,             // Monday Board ID
        calleeName  : "",               // Calls History from our DB
        callsHistory: [],               //
        instanceId  : null,             // our App Instance ID
        isLoading   : true,
        isOnBoard   : false,            // Is Total Zoom Calls Column on Board?
        isSyncing   : false,
        isZoomAuth  : false,            // Info about Zoom Authentication
        itemId      : null,             // Monday Item ID
        mainLink    : "",               // Used to Redirect to Different Skins of the App
        mondayToken : null,             // Monday Access Token
        phone       : [],               // a Set of the Items Phone Numbers
        theme       : null,             // Monday Theme
        userId      : null,             // Monday User ID
        userStatus  : null,             // Admin, Unlicensed, Guest
        debug       : null
    });

    return (
        <AppContext.Provider value={{ state, setState }}>
            {children}
        </AppContext.Provider>
    );
};