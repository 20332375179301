import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import appInsights from "./telemetry/appInsights";
import "monday-ui-react-core/dist/main.css";
import "./App.css";
import { ContextProvider } from "./context";
import TableDataView from "./pages/TableDataView/TableDataView";
import ZoomAuth from "./pages/ZoomAuth/ZoomAuth";
import ActivityDataView from "./pages/ActivityDataView/ActivityDataView";
import BoardView from "./pages/BoardView/BoardView";
import Unlicensed from "./pages/Unlicensed/Unlicensed";
import Home from "./pages/Home/Home";
import Viewer from "./pages/Viewer/Viewer";

appInsights.loadAppInsights();

export default function App() {
    console.log("\n\nApp..");

    return (
        <Router>
            <ContextProvider>
                <div className='App'>
                    <Routes>
                        <Route path='/' element={<Home/>}/>
                        <Route path='/viewer' element={<Viewer/>}/>
                        <Route path='/table' element={<TableDataView/>}/>
                        <Route path='/activity' element={<ActivityDataView/>}/>
                        <Route path='/login' element={<ZoomAuth/>}/>
                        <Route path='/board' element={<BoardView/>}/>
                        <Route path='/unlicensed' element={<Unlicensed/>}/>
                    </Routes>
                </div>
            </ContextProvider>
        </Router>
    );
};