import React, { useContext, useEffect } from "react";
import { Button } from "monday-ui-react-core";
import { AppContext } from "../../../context";
import {
    checkColumnOnBoard, createZoomColumnOnBoard, removeTotalCallsFromBoard
} from "../../../monday/mondayApi";
import addDataToColumns from "../../../utils/addDataToColumns";


export default function AddColumnButtonOnBoard({ toggleIsSyncing }) {
    console.log("%cAdd Column Button On Board...", "color: green");

    const { state, setState } = useContext(AppContext);
    const { boardId, isOnBoard, mondayToken, theme } = state;

    useEffect(() => {
        (async () => {
                const columnId = await checkColumnOnBoard(boardId);
                console.log("Is Zoom Column on Board? ", columnId);
                if (!columnId) {return;}

                setState({ ...state, isOnBoard: true });
            }
        )();
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Handle on Button Click
     * @returns {Promise<void>}
     */
    const handleBoardClick = async () => {
        console.log("%cHandle on Button Click...", "color: purple", "isOnBoard", isOnBoard);

        if (isOnBoard) {
            await removeTotalCallsFromBoard(boardId, newIsOnBoard);
            return;
        }

        toggleIsSyncing(true);

        const createColumn = await createZoomColumnOnBoard(boardId);
        console.log("==== createColumn ==> ", createColumn);
        if (!createColumn) {
            console.log("Failed to Create New Column");
            toggleIsSyncing(false);
            return;
        }

        const isDataAdded = await addDataToColumns(boardId, mondayToken);
        if (!isDataAdded) {
            console.log("Failed to Add Data to Column");
            toggleIsSyncing(false);
            return;
        }
        newIsOnBoard(true);
        toggleIsSyncing(false);
    };

    const newIsOnBoard = (value) => {
        setState({ ...state, isOnBoard: value });
    };

    return (
        <Button onClick={() => handleBoardClick()} className={theme}>
            {isOnBoard ? "Remove from Board" : "Add on Board"}
        </Button>
    );
}