import { useEffect, useState } from 'react';
import { mondayAuthenticateOnClient } from './mondayAuthenticateOnClient';

const useMondaySeamlessAuth = () => {
    // Declare a new state variable for the loading state
    const [isLoading, setIsLoading] = useState(true);

    // Declare a state variable for the data
    const [data, setData] = useState(null);

    // Declare a state variable for the error
    const [error, setError] = useState(null);

    const [trigger, setTrigger] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userDetails = await mondayAuthenticateOnClient();
                setData(userDetails);
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData().then();
    }, [trigger]); // Depend on 'trigger' state

    const reauthenticate = () => {
        setIsLoading(true);
        setError(null); // Reset the error state
        setTrigger(prev => prev + 1);
    };

    return { isLoading, data, error, reauthenticate };
};

export default useMondaySeamlessAuth;
