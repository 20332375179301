import axios from "axios";

export default async function fetchTotalCalls(mondayToken, phones) {
    console.log("%cFetch Total Calls from Backend...", "color: purple");

    if (phones && mondayToken) {
        try {
            const record = await axios.post(
                `${process.env.REACT_APP_API_URL}call/total`,
                { phones },
                { headers: { Authorization: `Bearer ${mondayToken}` } }
            );
            if (record.status !== 200) {
                console.log("Getting history response status !== 200");
                return null;
            }

            return record.data.totalCalls;
        } catch (error) {
            console.error("Getting history error", error);
            return null;
        }
    }
};