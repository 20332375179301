import { DialogContentContainer } from 'monday-ui-react-core';
import { Heading } from 'monday-ui-react-core/next';


const Modal = ({ loadingText }) => {
	const parts = loadingText.split(/(?<=\. )/);

	return (
		<DialogContentContainer
			type={DialogContentContainer.types.POPOVER}
			style={{
				display  : 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
				height   : '50vh', width: '80%', position: 'fixed', top: '50%', left: '50%',
				transform: 'translate(-50%, -50%)', textAlign: 'center', zIndex: 9999
			}}>
			<Heading type={Heading.types.H3}>{parts[0]}</Heading>
			{parts[1] && <Heading type={Heading.types.H3}>{parts[1]}</Heading>}
		</DialogContentContainer>
	);
};

export default Modal;