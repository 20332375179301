import React, { useContext, useEffect, useState } from "react";
import { Box, Flex } from "monday-ui-react-core";
import DropDownMenu from "../components/buttons/DropDownMenu/DropDownMenu";
import { AppContext } from "../context";
import Modal from "../components/Modal/Modal";
import Spinner from "../components/Spinner/Spinner";
import fetchCallHistory from "../api/fetchCallHistory";
import {
    checkColumnOnBoard, getMondayItemDetails, saveTotalCallsOnBoard,
    triggerValueCreatedEvent
} from "../monday/mondayApi";
import MissingCallButton from "../components/buttons/MissingCallButton/MissingCallButton";
import CallToButton from "../components/buttons/CallToButton/CallToButton";
import addDataToColumns from "../utils/addDataToColumns";


export default function Layout({ children }) {
    const { state, setState } = useContext(AppContext);
    const { boardId, phone, mondayToken, theme, isLoading, isOnBoard, isZoomAuth, itemId } = state;
    const [isSyncing, setIsSyncing] = useState(false);
    const [isBoardSyncing, setIsBoardSyncing] = useState(false);


// Sync Total Calls On Board
    useEffect(() => {
        if (boardId && isOnBoard && mondayToken) {

            (async () => {
                setIsBoardSyncing(false);
                await addDataToColumns(boardId, mondayToken);
                setIsBoardSyncing(false);
                console.log('Board is filled with Data Up');
            })();
        }
    }, [boardId, isOnBoard, mondayToken]);


// Get Calls History
    useEffect(() => {
        if (isZoomAuth && phone.length > 0 && mondayToken) {
            console.log("Get history...");

            setIsSyncing(true);

            (async () => {
                const callsHistory = await fetchCallHistory(phone, mondayToken);

                if (isOnBoard) {
                    await saveTotalCallsOnBoard(boardId, itemId, callsHistory);
                }

                setState({ ...state, callsHistory, isLoading: false });
                triggerValueCreatedEvent();
            })();

            setIsSyncing(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isZoomAuth, phone, mondayToken]);


// Get Callee Info
    useEffect(() => {
        if (itemId) {
            console.log("Get Monday Item Details...");

            (async () => {
                const columnId = await checkColumnOnBoard(boardId);
                if (!columnId) {localStorage.removeItem(`dateOfTotalCallsUpdate-${boardId}`);}

                const { name, phones } = await getMondayItemDetails(itemId, boardId);
                setState(
                    { ...state, phone: phones, calleeName: name, isLoading: false, isOnBoard: !!columnId });
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemId]);


    // change isSyncing
    function changeIsSyncing(value) {
        setIsSyncing(value);
    }

    return (
        <Box marginX={Box.marginXs.SMALL} className={theme}>
            <Flex direction={Flex.directions.ROW} justify={Flex.justify.SPACE_BETWEEN}
                  align={Flex.align.CENTER} style={{ paddingBottom: "8px" }}>
                {isLoading && <Spinner/>}

                {isBoardSyncing &&
                    (<Modal
                        loadingText='Please do not close this window while this message is visible.
									We calculate the calls you’ve done...'/>)}

                {mondayToken && isSyncing &&
                    (<Modal loadingText='Fetching the latest records from Zoom...'/>)}

                <CallToButton/>

                <Flex direction={Flex.directions.ROW} justify={Flex.justify.SPACE_BETWEEN}
                      align={Flex.align.CENTER} gap={Flex.gaps.LARGE}>
                    <MissingCallButton changeIsSyncing={changeIsSyncing}/>
                    <DropDownMenu/>
                </Flex>

            </Flex>
            {(isZoomAuth && !isLoading) ? children : null}
        </Box>
    );
}