import React, { useContext } from 'react';
import { Box } from 'monday-ui-react-core';
import CallLine from '../../components/CallLine/CallLine';
import appInsights from '../../telemetry/appInsights';
import { AppContext } from '../../context';
import Layout from '../Layout';
import NoPhoneNumber from '../../components/NoPhoneNumber/NoPhoneNumber';
import NoHistory from '../../components/NoHistory/NoHistory';
import SmsLine from '../../components/SmsLine/SmsLine';

appInsights.trackEvent({ name: 'Calls_screen_rendered' });


export default function TableDataView() {
	console.log("%cTable Data View...", "color: green");
	const { state } = useContext(AppContext);
	const { callsHistory, phone } = state;


	return (
		<Layout>

			{phone.length > 0 && callsHistory.length === 0 && <NoHistory/>}

			{phone.length === 0 && <NoPhoneNumber/>}

			{phone.length > 0 && callsHistory.length !== 0 &&
				<Box className='call_table'>
					<table style={{ width: '100%', tableLayout: 'fixed' }} >
						<tbody>
							{callsHistory?.map((call, index) => {
								let line = <CallLine call={call} key={index}/>;
								if (call.message) {
									line = <SmsLine sms={call} key={index}/>;
								}
								return line;
							})}
						</tbody>
					</table>
				</Box>
			}

		</Layout>
	);
};