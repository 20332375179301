import { Menu, MenuButton, MenuItem } from 'monday-ui-react-core';
import { signOut } from '../../../api/apiServices';
import appInsights from '../../../telemetry/appInsights';
import { useContext } from 'react';
import { AppContext } from '../../../context';
import { useNavigate } from 'react-router-dom';

const DropDownMenu = () => {
	const navigate = useNavigate();
	const { state, setState } = useContext(AppContext);
	const { theme, mondayToken } = state;

	const handleSignOutClick = async () => {
		console.log('SignOut clicked');

		appInsights.trackEvent({ name: `sign_out_clicked` });
		const signOutResult = await signOut(mondayToken);

		if (signOutResult?.status === 204) {
			setState({ ...state, isZoomAuth: false });
		}
		navigate('/login', { replace: true });
	};

	const handleRedirectClick = () => {
		console.log('How To Use clicked');
		appInsights.trackEvent({ name: `how_to_use_clicked` });
		window.open('https://getreport.ai/monday/zoom-phone/', '_blank');
	};

	const handleRequestClick = () => {
		console.log('Suggest Feature clicked');
		appInsights.trackEvent({ name: `suggest_feature_clicked` });
		window.open('https://getreportsupport.atlassian.net/servicedesk/customer/portal/3/group/3/create/21',
			'_blank');
	};

	return (
		<MenuButton className={theme}>
			<Menu id='menu' size='medium'>
				<MenuItem onClick={handleRequestClick} title='Suggest a feature'/>
				<MenuItem onClick={handleRedirectClick} title='How to use it?'/>
				<MenuItem onClick={handleSignOutClick} title='Zoom SignOut'/>
			</Menu>
		</MenuButton>
	);
};

export default DropDownMenu;