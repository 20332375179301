import { useContext, useState } from "react";
import { AppContext } from "../../context";
import { Box, Flex, Icon, Text, Tooltip } from "monday-ui-react-core";
import { Time, Update } from "monday-ui-react-core/icons";

export default function SmsLine({ sms }) {
    const { state } = useContext(AppContext);
    const [fullText, setFullText] = useState(false);
    const { phone, calleeName } = state;
    const { date, tooltipDate, sender, receiver, direction, message, senderName } = sms;

    let smsPhone = phone.filter(item => receiver.includes(item));
    smsPhone = smsPhone.length !== 0 ? smsPhone[0] : receiver[0];


    return (
        <>
            <tr>
                {/*---date---*/}
                <td style={{ width: "14%" }}>
                    <Box marginY={Box.marginYs.XS}>
                        <Tooltip content={tooltipDate} containerSelector='body'>
                            <Flex direction={Flex.directions.ROW} gap={Flex.gaps.SMALL}>
                                <Text type={Text.types.TEXT2}>
                                    <Icon iconType={Icon.type.SVG} icon={Time}/>
                                </Text>
                                <Text type={Text.types.TEXT2}> {date}</Text>
                            </Flex>
                        </Tooltip>
                    </Box>
                </td>

                {/*---type---*/}
                <td style={{ width: "3%" }}>
                    <Text type={Text.types.TEXT2}>
                        <Icon iconType={Icon.type.SVG} icon={Update}/>
                    </Text>
                </td>

                {/*---sender name---*/}
                <td>
                    <Tooltip content={direction === "In: " ? `From ${sender}` : `To ${smsPhone}`} containerSelector='body'>
                        <Text type={Text.types.TEXT2} ellipsis={false}>
                            {senderName ? senderName : calleeName}
                        </Text>
                    </Tooltip>
                </td>

                {/*---message---*/}
                <td colSpan='2'>
                    <Text type={Text.types.TEXT2} element='p' style={{ margin: 0, cursor: "pointer" }}
                          onClick={() => setFullText(!fullText)}>
                        {/*{message}*/}
                        {message?.length <= 50 ?
                            message :
                            !fullText ?
                                message.slice(0, 50) + "..." :
                                message
                        }

                    </Text>
                </td>
            </tr>
        </>
    );
}