import { Flex, Loader } from 'monday-ui-react-core';

const Spinner = () => {
	return (
		<Flex justify={Flex.justify.CENTER} align={Flex.align.CENTER} style={{ height: '80vh' }}>
			<Loader color={Loader.colors.PRIMARY} size={40}/>
		</Flex>
	);
};

export default Spinner;