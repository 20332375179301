import { Button } from "monday-ui-react-core";
import appInsights from "../../../telemetry/appInsights";
import mondaySdk from "monday-sdk-js";
import { useEffect, useState } from "react";
import { Notify } from "notiflix";

const monday = mondaySdk();
monday.setApiVersion("2023-10");

const ZoomAuthButton = () => {
    const [token, setToken] = useState();

    useEffect(() => {
        if (!monday) {
            return;
        }

        (async () => {
            const { data: mondayToken } = await monday.get("sessionToken");
            console.log("==== mondayToken?.length ==> ", mondayToken?.length);
            setToken(mondayToken);
        })();// eslint-disable-next-line
    }, [monday]);


    const handleAuth = async () => {
        if (!token) {
            Notify.failure("It seems there was an issue retrieving the necessary credentials to proceed. Please refresh the page to try again.");
            return;
        }
        console.log("\nLogin Button clicked...");
        appInsights.trackEvent({ name: "login_button_click" });

        //* open pop up window
        window.open(
            `${process.env.REACT_APP_API_URL}auth/getauth?id=${token}`,
            "_blank",
            "width=500, height=600"
        );
    };

    return (
        <>
            {token ?
                <Button onClick={handleAuth}>Login with Zoom</Button> : null
            }
        </>
    );
};

export default ZoomAuthButton;
