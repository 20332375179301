import { Time, LogIn, LogOut, Play, CloseRound } from "monday-ui-react-core/icons";
import { Box, Flex, Icon, Text, Tooltip } from "monday-ui-react-core";
import { useContext, useState } from "react";
import fetchRecord from "../../api/fetchRecord.js";
import { AppContext } from "../../context";

export default function CallLine({ call }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [recordUrl, setRecordUrl] = useState("");
    const [isDownloading, setIsDownloading] = useState(false);
    const { state } = useContext(AppContext);
    const { phone, mondayToken } = state;
    const credentials = { phone, mondayToken };
    const [message, setMessage] = useState("Please wait while the call recording is being loaded.");

    const {
              date,
              tooltipDate,
              direction,
              callerName,
              result,
              duration,
              recordingId,
              callId,
              number,
              dateFromZoom
          } = call;

    const onRecordClick = async () => {
        if (!phone || !mondayToken || !callId) {
            console.error("Missing credentials or callId");
            return;
        }

        try {
            setIsDownloading(true);
            const record = await fetchRecord(credentials, callId, dateFromZoom.slice(0, 10));
            if (!record) {
                setIsDownloading(false);
                return;
            }
            else if (record === 300) {
                setMessage(
                    "The requested recording is no longer available, as Zoom retains recordings for only the past 6 months.");
                return;
            }

            const blob = new Blob([new Uint8Array(record.data)], { type: "audio/mpeg" });
            const recordUrl = URL.createObjectURL(blob);

            setRecordUrl(recordUrl);
            setIsDownloading(false);
            setIsPlaying(true);
        } catch (error) {
            console.error("Failed to fetch the record: ", error);
        }
    };


    const onCloseBtnClick = () => {
        setIsPlaying(false);
        setIsDownloading(false);
    };

    return (
        <>
            <tr>
                <td style={{ width: "10%" }}>
                    <Box marginY={Box.marginYs.XS}>
                        <Tooltip content={tooltipDate} containerSelector='body'>

                            <Flex direction={Flex.directions.ROW} gap={Flex.gaps.SMALL}>
                                <Text type={Text.types.TEXT2}>
                                    <Icon iconType={Icon.type.SVG} icon={Time}/>
                                </Text>
                                <Text type={Text.types.TEXT2}> {date}</Text>
                            </Flex>
                        </Tooltip>
                    </Box>
                </td>

                <td style={{ width: "3%" }}>
                    <Text type={Text.types.TEXT2}>
                        <Icon iconType={Icon.type.SVG} icon={direction === "inbound" ? LogIn : LogOut}/>
                    </Text>
                </td>

                <td style={{ width: "22%" }}>
                    <Text type={Text.types.TEXT2}>
                        {callerName}
                    </Text>
                </td>

                <td style={{ width: "18%" }}>
                    <Text type={Text.types.TEXT2}>
                        {number}
                    </Text>
                </td>

                <td style={{ width: "20%" }}>
                    <Text type={Text.types.TEXT2}>
                        {result}
                    </Text>
                </td>

                <td style={{ width: "10%" }}>
                    <Text type={Text.types.TEXT2}>
                        <Flex direction={Flex.directions.ROW} align={Flex.align.CENTER} gap={Flex.gaps.SMALL}>
                            {duration}{" "}
                            {recordingId !== null ? (
                                <Icon icon={Play} clickable={true} iconLabel='Listen to the call record'
                                      onClick={onRecordClick}/>
                            ) : null}</Flex>
                    </Text>
                </td>

            </tr>

            {isPlaying && (
                <tr>
                    <td colSpan='5'>
                        <audio controls style={{ width: "95%", padding: "20px", paddingTop: "0px" }}>
                            <source src={recordUrl} type='audio/mpeg'/>
                        </audio>
                    </td>
                    <td>
                        <Icon icon={CloseRound} clickable={true} iconLabel='Close the call record'
                              onClick={onCloseBtnClick} controls
                              style={{ paddingBottom: "20px", paddingTop: "0px" }}/>
                    </td>
                </tr>
            )}

            {isDownloading && (
                <tr>
                    <td colSpan='5'>
                        <Text type={Text.types.TEXT2}
                              style={{ paddingBottom: "20px", paddingTop: "20px", textAlign: "center" }}>
                            {message}
                        </Text>
                    </td>
                    <td>
                        <Icon icon={CloseRound} clickable={true} iconLabel='Close the call record'
                              onClick={onCloseBtnClick} controls
                              style={{ paddingBottom: "20px", paddingTop: "0px" }}/>
                    </td>
                </tr>
            )}
        </>
    );
};