import { frontendAuthServerMondaySeamlessAuth } from './frontendAuthServerClient';
import { getMondayCurrentUserInfo, getMonday } from './mondayFrontendSdk';
const monday = getMonday()
monday.setApiVersion("2023-10");

export const mondayAuthenticateOnClient = async () => {
    const userDetails = await getMondayCurrentUserInfo();

    const token = await getMonday().get('sessionToken');

    return await frontendAuthServerMondaySeamlessAuth({
        userDetails: userDetails,
        mondayAccessToken: token.data,
    });
};