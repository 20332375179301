import "monday-ui-style/dist/index.min.css";
import { Modal, ModalContent, ModalFooterButtons } from "monday-ui-react-core";
import { useEffect, useState } from "react";
import mondaySdk from "monday-sdk-js";

const monday = mondaySdk();

export default function Unlicensed() {
    console.log("%Unlicensed Page ...", "color: green");
    const [subDomain, setSubDomain] = useState(null);

    useEffect(() => {
        (async () => {// eslint-disable-next-line
                monday.listen("location", ({ data }) => {
                    const { href } = data;
                    const slug = href ? new URL(href).hostname.split(".")[0] : null;
                    setSubDomain(slug);
                });
            }
        )();
    }, []);


    const onButtonClick = () => {
        window.open(`https://${subDomain}.monday.com/apps/installed_apps/10092396`, "_blank");
    };

    return (
        <Modal
            title='Your license has expired'
            show={true}
            contentSpacing={true}
            hideCloseButton={true}
        >
            <ModalContent>
                <p>Your Zoom Phone integration with monday CRM app is currently without a license.</p>
                <p>To activate and access all features:</p>

                <ul>
                    <ol>1. Go to the monday apps marketplace (🧩 icon top right corner).</ol>
                    <ol>2. Search for “Zoom Phone”.</ol>
                    <ol>3. Follow the instructions there to obtain the license.</ol>
                </ul>
                <p>Need help?</p>
                <p>Reach out to <a href='mailto:zoomphone@getreport.ai'>zoomphone@getreport.ai</a></p>
            </ModalContent>
            {subDomain &&// eslint-disable-next-line
                <ModalFooterButtons onPrimaryButtonClick={onButtonClick} primaryButtonText='Visit our app page'/>
            }

        </Modal>

    );
}