import {
    fetchPhoneDataFromBoard, saveAllTotalCallsOnBoard
} from "../monday/mondayApi";
import fetchTotalCalls from "../api/fetchTotalCalls";

/**
 * Add data to Columns
 * @param boardId
 * @param mondayToken
 * @returns {Promise<boolean>}
 */
export default async function addDataToColumns(boardId, mondayToken) {
    console.log("%c\nAdd Data To Columns func...", "color: purple");
    try {
        const lastUpdate = localStorage.getItem(`dateOfTotalCallsUpdate-${boardId}`);
        const today = (new Date()).toDateString();
        const lastUpdateDate = (new Date(lastUpdate)).toDateString();

        if (lastUpdateDate === today) {
            console.log("%cNext update will be tomorrow",  "color: red");
            return null;
        }

// Get Phones Lists per itemId
        const phones = await fetchPhoneDataFromBoard(boardId);
        if (!phones) {
            console.log("%cThere is no phone",  "color: red");
            return null;
        }

// Fetch Total Calls Numbers
        const totalCalls = await fetchTotalCalls(mondayToken, phones);
        if (!totalCalls) {
            console.error("%cNo data fetched",  "color: red");
            return null;
        }

// Save Total Calls on Board
        const res = await saveAllTotalCallsOnBoard(boardId, totalCalls);
        if (!res) {
            console.error("%cFailed to save data on board",  "color: red");
            return null;
        }

        localStorage.setItem(`dateOfTotalCallsUpdate-${boardId}`, today.toString());

        return true;
    } catch (error) {
        console.error("Add Data to Column Error", error);
        return null
    }

}
