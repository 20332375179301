import React from 'react';
import { Flex, List, ListItem, Text } from 'monday-ui-react-core';
import { Heading } from 'monday-ui-react-core/next';

export default function NoPhoneNumber() {

	return (
		<Flex direction={Flex.directions.COLUMN} justify={Flex.justify.CENTER} align={Flex.align.CENTER}
		      gap={Flex.gaps.MEDIUM} style={{ height: '80vh' }}>

			<Heading type={Heading.types.H3}>🧐 We don't see a phone number for this item.</Heading>

			<Text type={Text.types.TEXT2}>Please make sure to </Text>

			<List component={List.components.OL}>
				<ListItem component={ListItem.components.LI}>
					1. have a column of the <strong>type Phone 📞</strong>
				</ListItem>
				<ListItem component={ListItem.components.LI}>
					2. that has the valid <strong>phone number</strong> in it.
				</ListItem>
			</List>
		</Flex>
	);
}