import { frontendAuthServerMondaySeamlessAuth } from './frontendAuthServerClient';

export const getMonday = () => {
    return window.mondaySdk();
};

export const getMondayCurrentUserInfo = async () => {
    const monday = getMonday();
    monday.setApiVersion("2023-10");

    const result = await monday.api(`
                query {
                    me {
                        name
                        photo_thumb
                        email
                    }
                }`);
    return result.data.me;
};

export const mondayAuthenticateOnClient = async () => {
    const userDetails = await getMondayCurrentUserInfo();
    const token = await getMonday().get('sessionToken');

    return await frontendAuthServerMondaySeamlessAuth({
        userDetails: userDetails,
        mondayAccessToken: token.data,
    });
};