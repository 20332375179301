export async function serverPostCall(path, extraHeaders, body) {
    let headersInit = {
        'Content-Type': 'application/json',
    };
    // append headers
    if (extraHeaders) {
        headersInit = {
            ...headersInit,
            ...extraHeaders,
        };
    }

    const response = await fetch(path, {
        method: 'POST',
        headers: headersInit,
        body: JSON.stringify(body),
    });

    // If the status is 401 (Unauthorized), return null
    if (response.status === 401) {
        console.warn('Unauthorized', path);
        return null;
    }

    // If the response is not ok, and it's not 401, throw an error
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return await response.json();
}

const authServerUrl = process.env.REACT_APP_NEXT_PUBLIC_AUTH_SERVER_URL;

const publicToken =
    process.env.REACT_APP_NEXT_PUBLIC_AUTH_SERVER_PUBLIC_API_KEY;

export const frontendAuthServerMondaySeamlessAuth = async options => {
    try {
        return await authServerPostCall(
            authServerUrl + '/public/monday/seamless-authenticate',
            options
        );
    } catch (error) {
        console.error(error);
        return null;
    }
};

async function authServerPostCall(path, body) {
    return await serverPostCall(
        path,
        {
            PublicApiToken: publicToken,
        },
        body
    );
}