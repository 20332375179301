import mondaySdk from "monday-sdk-js";
import appInsights from "../telemetry/appInsights";

const monday = mondaySdk();

monday.setApiVersion("2023-10");
const { get, execute, api } = mondaySdk();


const getMondayContext = async () => {
    try {
        return await get("context");
    } catch (error) {
        console.log("Error getting user status", error);
        return null;
    }
};


const getMondayItemDetails = async (itemId) => {
    console.log("\nGet Monday Item Details...");

    try {
        const res = await monday.api(
            `query { items (ids: ${itemId}) { name column_values {column {title} id value type text } } }`, {
                apiVersion: "2023-10"
            });

        appInsights.trackEvent({ name: `callee_item_uploaded`, properties: { data: res.data.items } });

        const callee = res.data?.items[0];
        const phoneColumns = callee?.column_values?.filter(el => el.type === "phone");

        let phones = [];
        let poneColumnId = [];
        const regExp = /^[+\s\d]*$/;
        phoneColumns.forEach(column => {
                if (column?.text?.length > 4 && regExp.test(column?.text)) {
                    phones.push(column.text);
                    poneColumnId.push(column.id);
                }
            }
        );

        console.log("Phones: ", phones);
        return { name: callee.name, phones, poneColumnId };
    } catch (err) {
        console.error("Monday item error", err);
    }
};


const triggerValueCreatedEvent = () => {
    try {
        execute("valueCreatedForUser");
    } catch (e) {
        console.error(`Monday value delivering error`);
    }
};


const checkColumnOnBoard = async (boardId) => {
    console.log("%cCheck Column On Board...", "color: purple");
    const query = `query { boards(ids: ${boardId}) {columns {id}}}`;

    try {
        const res = await api(query, {
            apiVersion: "2023-10"
        });
        const columnId = res?.data?.boards[0].columns.filter(el => el.id.includes("zoom_calls_"))[0]?.id;
        if (!columnId) {return null;}

        return columnId;
    } catch (error) {
        console.log("Check Column on Board Error: ", error);
        return null;
    }
};


const createZoomColumnOnBoard = async (boardId) => {
    console.log(`\nCreate Zoom Column on Board ${boardId}...`);

    try {
        const phoneColumnId = await searchColumnsWithPhone(boardId);
        console.log("Board has Columns with Phone Numbers: ", phoneColumnId);
        if (!phoneColumnId) {
            console.log("No column with phone number found");
            return null;
        }

        const query = `
	    mutation {
	      create_column (
	      board_id: ${boardId}, 
	      id: "zoom_calls_${[...Array(4)].map(() => String.fromCharCode(Math.floor(Math.random() * 26) + 97))
            .join("")}", 
	      title: "Total Zoom Calls", 
	      description: "Here is total number of your zoom calls", 
	      column_type: numbers,
	      after_column_id: "${phoneColumnId[0]}"
	      ) {
	        id
	        title
	        description
	      }
	    }`;

        const response = await api(query, {
            apiVersion: "2023-10"
        });
        console.log("The new column created:", response);
        return response;

    } catch (error) {
        console.error("Create Zoom Column on Board Error: ", error);
        return false;
    }
};


const saveTotalCallsOnBoard = async (boardId, itemId, callsHistory) => {
    console.log(`Save Total Calls on Board ${boardId}...`);

    const columnId = await checkColumnOnBoard(boardId);
    if (!columnId) {return null;}

    const total = typeof callsHistory === "number" ? callsHistory : callsHistory.filter(
        el => !el.message).length;

    const query = `
	  mutation {
	    change_simple_column_value (
	      board_id: ${boardId},
	      item_id: ${itemId},
	      column_id: "${columnId}",
	      value: "${total.toString()}"
	    ) {id}}`;

    api(query, {
        apiVersion: "2023-10"
    }).then(response => {
        console.log(response);
    }).catch(error => {
        console.log("STCoB Error: ", error);
    });
};


const saveBatchTotalCallsOnBoard = async (boardId, batch) => {
    try {
        const columnId = await checkColumnOnBoard(boardId);
        if (!columnId) {
            console.log("No columns with Zoom Phone");
            return false;
        }

        const mutations = batch.map((el, index) => `
          update${index + 1}: 
              change_column_value(
                  item_id: ${el.itemId}, 
                  board_id: ${boardId}, 
                  column_id: "${columnId}", 
                  value: "${el.total}") 
                  {id}
      `).join("\n");

        const query = `mutation { ${mutations} }`;
        const res = await api(query, {
            apiVersion: "2023-10"
        });

        if (!res) {return false;}
        return true;
    } catch (e) {
        console.error("Saving data error: ", e);
        return false;
    }
};


const saveAllTotalCallsOnBoard = async (boardId, totalCalls) => {
    console.log("%cSave All Total Calls on Board...", "color: purple");
    let i = 0;
    const batchSize = 100;

    while (i < totalCalls.length) {
        const batch = totalCalls.slice(i, i + batchSize);

        const result = await saveBatchTotalCallsOnBoard(boardId, batch);

        if (!result) {
            console.log("%cFail saving of batch", "color: red");
            return null;
        }
        i += batchSize;
    }
    return totalCalls.length;
};


const removeTotalCallsFromBoard = async (boardId, newIsOnBoard) => {
    console.log(`Remove Total Calls From Board ${boardId}...`);

    try {
        const columnId = await checkColumnOnBoard(boardId);
        if (!columnId) {
            console.log("No columns with Zoom Phone Totals");
            return false;
        }
        const query = `
            mutation {
                delete_column (
                    board_id: ${boardId}, 
                    column_id: "${columnId}"
                )
                {id}
            }`;

        const result = await monday.api(query, { apiVersion: "2023-10" });

        localStorage.removeItem(`dateOfTotalCallsUpdate-${boardId}`);
        if (result) {
            newIsOnBoard(false);
        }
    } catch (error) {
        console.error("Remove Total Calls From Board: ", error);
        return false;
    }
};


const fetchPhoneDataFromBoard = async (boardId) => {
    console.log("%cFetch Item IDs from Board...", "color: purple");
    let page = 1;
    const limit = 100;
    let allData = [];
    let cursor = "";

    try {
        const columnIds = await searchColumnsWithPhone(boardId);
        if (!columnIds) { return null; }

        // Create string with Phone Numbers for query
        const columnIdsString = "\"" + columnIds.join("\", \"") + "\"";

        // Fetch Data and transform it to {itemId, phones[...]}
        while (cursor !== null) {
            const query = !cursor ? `query {
                boards(ids: ${boardId}) {
                    items_page (
                        limit: ${limit}, 
                        )
                            {
                                cursor
                                items {id name column_values (ids: [${columnIdsString}]) {column {title} text }}
                            }
                }}` :
                `query {
                    next_items_page (limit: ${limit}, cursor: "${cursor}") 
                            {
                                cursor
                                items {id column_values (ids: [${columnIdsString}]) {column {title} text }}
                            }
                }`;

            const res = await monday.api(query, { apiVersion: "2023-10" });

            const items = !cursor ? res?.data?.boards[0]?.items_page?.items : res?.data?.next_items_page?.items;
            cursor = !cursor ? res?.data?.boards[0]?.items_page?.cursor : res?.data?.next_items_page?.cursor;

            console.log(`%cItems. Pack #${page}: `, "color: blue", items?.length);
            if (!items?.length) {break;}

            allData = allData.concat(
                items.map(el => {
                    return {
                        itemId: el.id,
                        phones: el.column_values.map(obj => {
                            if (obj.text.length > 4) {return obj.text;}
                            return null;
                        }).filter(el => !!el)
                    };
                }).filter(el => el.phones.length > 0));

            if (items.length < limit) {break;}
            page++;
        }

        return allData.length ? allData : null;
    } catch (e) {
        console.error("Fetch Phone Data from Board Error: ", e);
        return null;
    }
};


const searchColumnsWithPhone = async (boardId) => {
    console.log("Search Columns With Phone...");
    const phrases = ["phone", "mobile", "tel"];
    const phoneColumnIds = [];

    try {
        const res = await api(`query { boards(ids: ${boardId}) { columns { id } } }`, {
            apiVersion: "2023-10"
        });

        const ids = res?.data?.boards[0]?.columns.map(el => el.id);

        ids.forEach(item => {
                if (phrases.some(word => item.includes(word))) {
                    phoneColumnIds.push(item);
                }
            }
        );

        return phoneColumnIds.length ? phoneColumnIds : null;
    } catch (e) {
        console.error("Search Column with Phone Number Error: ", e);
        return null;
    }
};


export {
    getMondayContext,
    getMondayItemDetails,
    triggerValueCreatedEvent,
    saveTotalCallsOnBoard,
    checkColumnOnBoard,
    createZoomColumnOnBoard,
    removeTotalCallsFromBoard,
    fetchPhoneDataFromBoard,
    saveAllTotalCallsOnBoard
};
