import React, { useContext } from 'react';
import { Box } from 'monday-ui-react-core';
import appInsights from '../../telemetry/appInsights';
import { AppContext } from '../../context';
import Layout from '../Layout';
import NoPhoneNumber from '../../components/NoPhoneNumber/NoPhoneNumber';
import NoHistory from '../../components/NoHistory/NoHistory';
import ActivityCard from '../../components/ActivityCard/ActivityCard';
import formatTime from '../../utils/timeUtils';

appInsights.trackEvent({ name: 'Calls_screen_rendered' });

export default function ActivityDataView() {
	console.log('\n\nActivity Data View...');
	const { state } = useContext(AppContext);
	const { callsHistory, phone } = state;


	return (
		<Layout>

			{phone.length > 0 && callsHistory.length === 0 && <NoHistory/>}

			{phone.length === 0 && <NoPhoneNumber/>}

			{phone.length > 0 && callsHistory.length !== 0 &&
				<Box backgroundColor={Box.backgroundColors.SECONDARY_BACKGROUND_COLOR}>
					<Box margin={Box.marginXs.XXXL} padding={Box.paddings.MEDIUM} >
						{callsHistory?.map((call, index) => {
							let month = null;
							if ((callsHistory[index - 1] && formatTime.month(call.dateFromZoom) !==
								formatTime.month(callsHistory[index - 1]?.dateFromZoom)) || index === 0) {
								month = formatTime.month(call.dateFromZoom);
							}
							return (
								<ActivityCard call={call} isLast={callsHistory.length - 1 === index}
								              key={index} month={month}/>
							);
						})}
					</Box>
				</Box>
			}

		</Layout>
	);
};