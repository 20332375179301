import 'monday-ui-style/dist/index.min.css';
import { DialogContentContainer } from 'monday-ui-react-core';
import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AppContext } from '../../context';
import { Heading } from "monday-ui-react-core/next";

export default function Viewer() {
	console.log("%cViewer Page ...", "color: green");

	const { state } = useContext(AppContext);
	const { theme } = state;
	const [searchParams] = useSearchParams();
	const status = searchParams.get('status') ? searchParams.get('status') : 'guest';

	return (
		<DialogContentContainer
			type={DialogContentContainer.types.POPOVER}
			style={{
				display       : 'flex',
				flexDirection : 'column',
				justifyContent: 'center',
				alignItems    : 'center',
				height        : '50%',
				width         : '80%',
				position      : 'fixed',
				top           : '50%',
				left          : '50%',
				transform     : 'translate(-50%, -66.66%)',
				textAlign     : 'center',
				zIndex        : 50
			}}
			className={theme}
		>
			<Heading type={Heading.types.H2}>
				As {status.toUpperCase()} you are unable to use the application
			</Heading>

		</DialogContentContainer>
	);
}