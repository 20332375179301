import axios from "axios";

async function synchronizeCalls(token, action) {
    try {
        const syncResults = await axios.post(
            `${process.env.REACT_APP_API_URL}call/sync?time=${action}`,
            {},
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        );

        if (!syncResults) {
            return null;
        }

        return syncResults.data.new_calls_added;
    } catch (error) {
        console.error("Sync error", error);
        return null;
    }
}

async function signOut(token) {
    try {
        return await axios.post(
            `${process.env.REACT_APP_API_URL}auth/signout`,
            {},
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        );
    } catch (error) {
        console.log(`Sign Out error.`);
        return null;
    }
}

async function checkAuth(token, data) {
    console.log("\nCheck Auth...");
    try {
        return await axios.post(
            `${process.env.REACT_APP_API_URL}auth/checkauth`,
            data,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        );
    } catch (error) {
        console.log(`Unauthorised`);
        return null;
    }
}

export { synchronizeCalls, signOut, checkAuth };