import { Box, Flex, Icon, IconButton, Text } from 'monday-ui-react-core';
import { LogIn, LogOut, Play } from 'monday-ui-react-core/icons';
import formatTime from '../../utils/timeUtils';
import { Edit } from 'monday-ui-react-core/icons';
import { Heading } from 'monday-ui-react-core/next';
import fetchRecord from '../../api/fetchRecord';
import { useContext, useState } from 'react';
import { AppContext } from '../../context';

export default function ActivityCard({ call, isLast, month }) {
	console.log('Activity Card');
	const { state: { phone, mondayToken } } = useContext(AppContext);
	const [recordUrl, setRecordUrl] = useState(null);
	const { direction, callerName, result, duration, recordingId, callId, number } = call;
	const { shortDate, shortTime } = formatTime.toShortDataTime(call.dateFromZoom);
	const credentials = { phone, mondayToken };
	console.log('===> call =', call);

	const onRecordClick = async () => {
		if (!phone || !mondayToken || !callId) {
			console.error('Missing credentials or callId');
			return;
		}

		try {
			const record = await fetchRecord(credentials, callId);
			if (record) {
				setRecordUrl(record);
			}
		} catch (error) {
			console.error('Failed to fetch the record: ', error);
		}
	};

	return (<>
			{month &&
				<Flex justify={Flex.justify.CENTER} style={{ margin: '32px' }}>
					<Box padding={Box.paddings.SMALL} shadow={Box.shadows.SMALL} rounded={Box.roundeds.SMALL}
					     backgroundColor={Box.backgroundColors.PRIMARY_BACKGROUND_COLOR}>
						<Text type={Text.types.TEXT2}>{month}</Text>
					</Box>
				</Flex>}

			<div style={{
				borderLeft: isLast ? 'none' : '1px solid gray', width: '100%', position: 'relative',
				height    : 'fit-content', paddingBottom: '48px'
			}}>

				{/*Left Top Icon*/}
				<div style={{
					width    : '26px', height: '26px', borderRadius: '4px', position: 'absolute',
					transform: 'translate(-50%, 0)', backgroundColor: 'orange', color: 'white',
					display  : 'flex', justifyContent: 'center', alignItems: 'center'
				}}>
					<Icon iconType={Icon.type.SVG} iconSize={20}
					      icon={direction === 'inbound' ? LogIn : LogOut}
					/>
				</div>

				<Flex direction={Flex.directions.COLUMN} gap={Flex.gaps.MEDIUM} align={Flex.align.START}
				      style={{ paddingLeft: '30px', paddingTop: '4px' }}>

					<Flex direction={Flex.directions.ROW} gap={Flex.gaps.XS}>
						<Text type={Text.types.TEXT2} weight={Text.weights.BOLD}>{shortDate},</Text>
						<Text type={Text.types.TEXT2}>{shortTime}</Text>
					</Flex>

					<div style={{ width: '100%' }}>
						<Box shadow={Box.shadows.SMALL} rounded={Box.roundeds.MEDIUM}
						     padding={Box.paddings.MEDIUM}
						     backgroundColor={Box.backgroundColors.PRIMARY_BACKGROUND_COLOR}>

							<Flex justify={Flex.justify.SPACE_BETWEEN} style={{ marginBottom: '24px' }}>
								<Heading type={Heading.types.H3}>Call Info:</Heading>

								<IconButton kind={IconButton.kinds.TERTIARY}
								            tooltipContent={'Edit Comment'}
								            icon={Edit} size={IconButton.sizes.XS}></IconButton>
							</Flex>

							<Flex direction={Flex.directions.COLUMN} align={Flex.align.START}
							      gap={Flex.gaps.MEDIUM}>
								<Flex direction={Flex.directions.ROW} justify={Flex.justify.START}
								      gap={Flex.gaps.LARGE} align={Flex.align.START} wrap>

									<Flex direction={Flex.directions.ROW} gap={Flex.gaps.SMALL}>
										<Text type={Text.types.TEXT2}>number: </Text>
										<Text type={Text.types.TEXT2}
										      weight={Text.weights.BOLD}>{number}</Text>
									</Flex>
									<Flex direction={Flex.directions.ROW} gap={Flex.gaps.SMALL}>
										<Text type={Text.types.TEXT2}>direction: </Text>
										<Text type={Text.types.TEXT2}
										      weight={Text.weights.BOLD}>{direction}</Text>
									</Flex>
									<Flex direction={Flex.directions.ROW} gap={Flex.gaps.SMALL}>
										<Text type={Text.types.TEXT2}>caller name: </Text>
										<Text type={Text.types.TEXT2}
										      weight={Text.weights.BOLD}>{callerName}</Text>
									</Flex>
									<Flex direction={Flex.directions.ROW} gap={Flex.gaps.SMALL}>
										<Text type={Text.types.TEXT2}>result: </Text>
										<Text type={Text.types.TEXT2}
										      weight={Text.weights.BOLD}>{result}</Text>
									</Flex>
									<Flex direction={Flex.directions.ROW} gap={Flex.gaps.SMALL}>
										<Text type={Text.types.TEXT2}>duration: </Text>
										<Text type={Text.types.TEXT2}
										      weight={Text.weights.BOLD}>{duration}</Text>
									</Flex>
								</Flex>

								{recordingId !== null && !recordUrl &&
									<Icon iconSize={32} icon={Play} clickable={true}
									      iconLabel='Listen to the call record'
									      onClick={onRecordClick}
									/>
								}

								{recordUrl ?
									<audio
										controls
										style={{
											width: '100%'
										}}>
										<source src={recordUrl} type='audio/mpeg'/>
									</audio> : null
								}

								<Text type={Text.types.TEXT2}>No comments</Text>
							</Flex>
						</Box>
					</div>

				</Flex>
			</div>
		</>
	);
}