import { Button } from 'monday-ui-react-core';
import React, { useContext } from 'react';
import appInsights from '../../../telemetry/appInsights';
import syncCalls from '../../../utils/syncCalls';
import { AppContext } from '../../../context';

export default function MissingCallButton({ changeIsSyncing }) {
	const { state, setState } = useContext(AppContext);
	const { mondayToken, phone, theme } = state;


	// click on 'Missing calls?' button handler
	async function handleSync(action = null) {
		console.log('Missed calls clicked');
		appInsights.trackEvent({ name: `missed_calls_clicked` });
		await changeIsSyncing(true);

		console.log('Sync Action: ', action);

		try {
			const callsHistory = await syncCalls(mondayToken, phone, action);
			if (!callsHistory) {
				console.log('Sync Error or no calls to sync');
				await changeIsSyncing(false);
				return;
			}

			setState({ ...state, callsHistory });
			await changeIsSyncing(false);
		} catch (error) {
			console.log('Sync error', error);
			await changeIsSyncing(false);
		}
	}

	return (
		<Button
			onClick={() => handleSync('all')}
			kind={Button.kinds.TERTIARY}
			size={Button.sizes.SMALL}
			className={theme}
		>
			Missing calls?
		</Button>
	);
}