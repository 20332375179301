import ZoomAuthButton from "../../components/buttons/ZoomAuthButton/ZoomAuthButton";
import { Box, Button, Flex, Icon, Text } from "monday-ui-react-core";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context";
import { useNavigate } from "react-router-dom";
import { Heading } from "monday-ui-react-core/next";
import { Close, LearnMore } from "monday-ui-react-core/icons";
import ModalNoZoomPhone from "../../components/ModalNoZoomPhone/ModalNoZoomPhone";


const ZoomAuth = () => {
    console.log("Zoom Auth Page...");
    const navigate = useNavigate();
    const [tooltipOne, setTooltipOne] = useState(false);
    const [toolTipTwo, setToolTipTwo] = useState(false);
    const { state, setState } = useContext(AppContext);
    const { theme, isZoomAuth, mainLink, calleeName } = state;
    const [hasZoomPhone, setHasZoomPhone] = useState(true);


    // get Zoom Phone auth result
    useEffect(() => {
        function handleAuthMessage(event) {
            console.log("Event.data", event.data);

            if (event.data === "Authorization completed successfully") {
                setState({ ...state, isZoomAuth: true });
                window.removeEventListener("message", handleAuthMessage);
            }
            else if (event.data === "No ZoomPhone") {
                setHasZoomPhone(false);
                window.removeEventListener("message", handleAuthMessage);
            }
        }

        window.addEventListener("message", handleAuthMessage);
        return () => {
            window.removeEventListener("message", handleAuthMessage);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isZoomAuth) {
            navigate(mainLink, { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isZoomAuth]);

    const handleModalClick = () => {
        setHasZoomPhone(true);
        navigate('/', { replace: true });
    };

    return (
        <Flex direction={Flex.directions.COLUMN} justify={Flex.justify.CENTER} align={Flex.align.CENTER}
              style={{ height: "80vh" }} className={theme} gap={Flex.gaps.LARGE}>

            <Heading type={Heading.types.H2}>
                Welcome to Zoom Phone integration to monday CRM!
            </Heading>

            <Heading type={Heading.types.H3}>
                In order to use this app you would need:
            </Heading>

            <Flex direction={Flex.directions.COLUMN} justify={Flex.justify.CENTER} align={Flex.align.CENTER}
                  className={theme} gap={Flex.gaps.SMALL}>
                <Box>

                    <Flex direction={Flex.directions.ROW} gap={Flex.gaps.SMALL}>
                        <Text type={Text.types.TEXT2} style={{ marginBottom: "8px" }}>
                            1. Zoom VOIP Phone account
                        </Text>
                        <div style={{ color: "var(--primary-text-color)" }}>
                            <Icon clickable iconType={Icon.type.SVG} icon={LearnMore} iconLabel='Learn more'
                                  iconSize={16} onClick={() => {
                                setToolTipTwo(false);
                                setTooltipOne(true);
                            }}/>
                        </div>

                    </Flex>

                    <Text type={Text.types.TEXT2}>2. Phone number in a separate column on</Text>
                    <Flex direction={Flex.directions.ROW} gap={Flex.gaps.SMALL}>
                        <Text type={Text.types.TEXT2} style={{ paddingLeft: "16px" }}> this board </Text>
                        <div style={{ color: "var(--primary-text-color)" }}>
                            <Icon clickable iconType={Icon.type.SVG} icon={LearnMore} iconLabel='Learn more'
                                  iconSize={16} onClick={() => {
                                setToolTipTwo(true);
                                setTooltipOne(false);
                            }}/>
                        </div>
                    </Flex>
                </Box>

                <div style={{
                    position : "absolute",
                    top      : "12%",
                    left     : "50%",
                    transform: "translate(-50%, -12%)",
                    width    : "50vw"
                }}>

                    {tooltipOne &&
                        <Box backgroundColor={Box.backgroundColors.SECONDARY_BACKGROUND_COLOR}
                             padding={Box.paddings.LARGE} rounded={Box.roundeds.MEDIUM}
                             shadow={Box.shadows.SMALL}>
                            <Flex direction={Flex.directions.COLUMN} align={Flex.align.START}
                                  gap={Flex.gaps.MEDIUM}>

                                <Flex direction={Flex.directions.ROW} justify={Flex.justify.SPACE_BETWEEN}
                                      style={{ width: "100%" }}>
                                    <Heading type={Heading.types.H3}>Tip 1</Heading>
                                    <Button kind={Button.kinds.TERTIARY} leftIcon={Close}
                                            size={Button.sizes.XS} onClick={() => {
                                        setToolTipTwo(false);
                                        setTooltipOne(false);
                                    }}></Button>
                                </Flex>

                                <Flex direction={Flex.directions.COLUMN} align={Flex.align.START}>
                                    <Text element='p' type={Text.types.TEXT2}>Zoom Phone is a separate service
                                        from Zoom that provides VOIP calling to any phone
                                        number</Text>
                                    <Text element='p' type={Text.types.TEXT2}>It is separate subscription from
                                        Zoom and starts $10/mo.</Text>
                                    <Text element='p' type={Text.types.TEXT2}>You will only benefit from this
                                        plugin if you already Phone subscription added to
                                        your Zoom account.
                                    </Text>
                                </Flex>

                            </Flex>
                        </Box>
                    }


                    {toolTipTwo &&
                        <Box backgroundColor={Box.backgroundColors.SECONDARY_BACKGROUND_COLOR}
                             padding={Box.paddings.LARGE} rounded={Box.roundeds.MEDIUM}
                             shadow={Box.shadows.SMALL}>
                            <Flex direction={Flex.directions.COLUMN} align={Flex.align.START}
                                  gap={Flex.gaps.MEDIUM}>

                                <Flex direction={Flex.directions.ROW} justify={Flex.justify.SPACE_BETWEEN}
                                      style={{ width: "100%" }}>
                                    <Heading type={Heading.types.H3}>Tip 2</Heading>
                                    <Button kind={Button.kinds.TERTIARY} leftIcon={Close}
                                            size={Button.sizes.XS} onClick={() => {
                                        setToolTipTwo(false);
                                        setTooltipOne(false);
                                    }}></Button>
                                </Flex>

                                <Flex direction={Flex.directions.COLUMN} align={Flex.align.START}>
                                    <Text element='p' type={Text.types.TEXT2}>Zoom Phone integration app pulls
                                        all the calls history for each user you’ve dialed.</Text>
                                    <Text element='p' type={Text.types.TEXT2}>In order for us to know what
                                        calls to show for <b>{calleeName}</b> we would need their phone number
                                        set in a separate Phone column for them.
                                    </Text>
                                    <Text element='p' type={Text.types.TEXT2}>We understand multiple numbers
                                        for one guy, just keep them in a separate columns.
                                    </Text>
                                </Flex>

                            </Flex>
                        </Box>
                    }
                </div>

            </Flex>
            <ZoomAuthButton/>
            {!hasZoomPhone && <ModalNoZoomPhone show={!hasZoomPhone} handleModalClick={handleModalClick}/>}
        </Flex>
    );
};

export default ZoomAuth;
