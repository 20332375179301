import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "monday-ui-react-core/dist/main.css";
import { AppContext } from "../../context";
import Spinner from "../../components/Spinner/Spinner";
import { checkAuth } from "../../api/apiServices";
import mondaySdk from "monday-sdk-js";
import useMondaySeamlessAuth from "../../monday/getReport/useMondaySeamlessAuth";

const monday = mondaySdk();
monday.setApiVersion("2023-10");


export default function Home() {
    console.log("\n\nHome Page...");

    const navigate = useNavigate();
    const { state, setState } = useContext(AppContext);
    const { mainLink } = state;
    const { data: mondayAuth } = useMondaySeamlessAuth();

// Get Monday Context and Check User Status
    useEffect(() => {
        console.log("\nGet Monday Context and Check User Status...");
        try {
            (async () => {
                const { data } = await monday.get("context");
                if (!data) {throw new Error("Failed access to Monday context");}

                const { user: { isViewOnly, isGuest, id }, instanceType } = data;

                const status = isViewOnly ? "viewer" : isGuest ? "guest" : "user";
                console.log("User Status: ", status);

                let itemValue, instanceId;
                if (instanceType) {
                    instanceId = data.instanceId;
                    itemValue = instanceType === "item_view" ? data.itemId
                        : instanceType === "board_view" && data.boardId;
                }

                if (status === "viewer" || status === "guest") {
                    navigate(`/viewer?status=${status}`, { replace: true });
                    return;
                }
                const { data: token } = await monday.get("sessionToken");
                if (!data || !token) {
                    // eslint-disable-next-line
                    throw new Error("Failed get context");
                }

                let mainLink = "/board";
                if (instanceType !== "board_view") {
                    mainLink = data.appFeature.name.toLowerCase().includes("activity") ?
                        "/activity" :
                        "/table";
                }

                setState(
                    {
                        ...state,
                        boardId    : data.boardId,
                        instanceId : instanceId,
                        itemId     : itemValue,
                        mainLink,
                        mondayToken: token,
                        theme      : data.theme,
                        userId     : id,
                        userStatus : status
                    });
                console.log("Monday context is OK\n-------------------------------");
            })();

        } catch (error) {
            console.error("", error);
        }

// eslint-disable-next-line
    }, []);


// Check ZoomAuth when component is mounted
    useEffect(() => {
        if (state.userStatus === "user" && state.mondayToken && mondayAuth) {
            (async () => {
                try {
                    console.log("==== mondayAuth ==> ", mondayAuth);
                    const { id: userId, pluginCompany: { id: companyId } } = mondayAuth?.pluginUser || {};
                    const authRes = await checkAuth(state.mondayToken, {userId, companyId});
                    const auth = authRes?.data?.isZoomAuth;
                    setState({ ...state, isZoomAuth: auth ? auth : false });
                    console.log("Zoom auth success: ", auth, "\n-------------\n\n");
                    if (!auth) {
                        navigate("login", { replace: true });
                        return;
                    }

                    navigate(mainLink, { replace: true });
                } catch (e) {
                    console.log("Zoom Auth Error: ", e);
                    navigate("/login", { replace: true });
                }
            })();
        }
// eslint-disable-next-line
    }, [state.userStatus, state.mondayToken, mondayAuth]);

    return <>{state.isLoading && <Spinner/>}</>;
};