import axios from 'axios';
import formatTime from '../utils/timeUtils';
import appInsights from '../telemetry/appInsights';

const fetchCallHistory = async (phone, mondayToken) => {
	if (phone.length > 0 && mondayToken) {
		console.log('Fetch Call History');
		let historyForRender = [];
		for (let i = 0; i < phone.length; i++) {
			try {
				const callHistory = await axios.post(
					`${process.env.REACT_APP_API_URL}call/log`,
					{ phone: phone[i] },
					{ headers: { Authorization: `Bearer ${mondayToken}` } }
				);

				if (callHistory?.data) {
					appInsights.trackEvent({ name: `calls_uploaded ${callHistory?.data.length}` });

					historyForRender.push(...callHistory?.data);
				}
			} catch (error) {
				appInsights.trackEvent({ name: `error_getting_calls ${error.message}` });
				console.error('Getting history error', error);
			}
		}

		historyForRender.forEach(el => {
			el.date = formatTime.dateToDays(el.date);
			el.tooltipDate = formatTime.toAmericanTime(el.tooltipDate);
			el.duration = formatTime.secondsToTime(el.duration);
		});

		return (historyForRender.sort(((a, b) => new Date(b.dateFromZoom) - new Date(a.dateFromZoom))));
	}
};

export default fetchCallHistory;