import { Box, Button, Flex, SplitButton, Text } from 'monday-ui-react-core';
import React, { useContext } from 'react';
import { AppContext } from '../../../context';
import { Mobile } from 'monday-ui-react-core/icons';
import appInsights from '../../../telemetry/appInsights';

export default function CallToButton() {
	const { state: { phone, calleeName } } = useContext(AppContext);

	// click on "Call <Name>" button handler
	async function handleCallClick(event) {
		console.log('Call Button clicked...');
		if (phone.length === 0) {return}

		const phoneNumber = phone.length === 1 ? phone[0] : event.target.innerText;
		appInsights.trackEvent({ name: `call_clicked ${phone[0]}` });
		window.location.href = `zoomphonecall:${phoneNumber}`;
	}

	return (
		<Flex direction={Flex.directions.ROW} gap={Flex.gaps.MEDIUM}>
			{phone.length === 1 ?

				<Button
					leftIcon={Mobile}
					onClick={handleCallClick}
					size={Button.sizes.SMALL}>
					Call {calleeName}
				</Button> :
				<SplitButton leftIcon={Mobile} children={`Call ${calleeName}`}
				             size={SplitButton.sizes.SMALL} secondaryDialogContent={
					<Box id='split-menu'>
						{phone.map((ph, index) => (
							<Text type={Text.types.TEXT2}
							      key={index}
							      style={{ cursor: 'pointer', padding: '5px' }}
							      onClick={handleCallClick}>{ph}</Text>))}
					</Box>}/>
			}
			{/*<Button leftIcon={Send} onClick={handleSMSClick} size={Button.sizes.SMALL}>*/}
			{/*	SMS*/}
			{/*</Button>*/}
		</Flex>
	);
}