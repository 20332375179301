import React from "react";
import { createRoot } from "react-dom/client";
import "monday-ui-react-core/tokens";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

const dsn = "https://2696830860e1816f95bafd00f372485b@o4505082012041216.ingest.sentry.io/4506553977012224";

if (process.env.REACT_APP_ENV !== "DEV") {
    Sentry.init({
        dsn,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                )
            }),
            new Sentry.Replay()
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        tracesSampleRate: 1.0,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0
    });
}

const root = createRoot(document.getElementById("root"));
root.render(<App/>);