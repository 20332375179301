import { Modal, ModalContent, ModalFooterButtons } from "monday-ui-react-core";

export default function ModalNoZoomPhone({ handleModalClick, show }) {
    const onCloseHandler = () => {
        handleModalClick();
    };

    return (
        <Modal
            onClose={onCloseHandler}
            title='No Phone in this Zoom account'
            show={show}
        >
            <ModalContent>
                <p>
                    Access to Zoom Phone Caller functionality requires an account with Zoom Phone enabled.
                </p>
                <b>Steps to Resolve:</b>
                <ul>
                    <ol>1. Go to <a href='https://zoom.us/pricing/zoom-phone'>Zoom website</a> to enroll into Zoom Phone add-on
                        (starts $10/month).
                    </ol>
                    <ol>2. Come back here and Log In.</ol>
                </ul>
                <p>If you already enrolled into Phone add-on in your Zoom, most likely you logged in with your personal account:</p>
                <ul>
                    <ol>1. Visit <a href='https://zoom.us/pricing/zoom-phone'>Zoom website</a> and log out of your current Zoom
                        account.
                    </ol>
                    <ol>2. Return to the Zoom Phone to monday integration login screen.</ol>
                    <ol>3. When prompted, log in with the Zoom credentials that have Zoom Phone enabled.</ol>
                </ul>
                <p>If you need additional assistance, please reach out to us via <a href='mailto:zoomphone@getreport.ai'>zoomphone@getreport.ai</a>.
                </p>
            </ModalContent>
            <ModalFooterButtons
                onPrimaryButtonClick={onCloseHandler}
                primaryButtonText='Back'
            />
        </Modal>
    );
}